<template>
  <div class="header-tab">
    <nav class="kb-nav-round-tabs">
      <ul v-if="isInit" class="kb-nav-list">
        <li v-for="(menu, idx) in learnMenus" class="kb-nav-item" :key="idx">
          <router-link v-if="menu.path" :to="menu.path" class="kb-nav-link" :class="{'is-active': menu.view === learnView}">
            <span class="kb-nav-text">{{ menu.name }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>

import {learnRouteSetup} from '@/assets/js/modules/learn/learn-setup';

export default {
  name: 'LearnRoute',
  props: {
    isInit: Boolean,
    referenceCnt: Number,
    collectiveCnt: Number,
    boardObjects: Array
  },
  setup: learnRouteSetup
}
</script>
