<template>

  <div class="main-content min-component">
    <div class="schedule-container">
      <div class="schedule-header">

        <div class="header-column"><button class="kb-btn-control" @click="moveWeek()"><i class="icon-prev"></i><span class="text">이전 주차</span></button></div>
        <div class="schedule-title"><h2 class="title">{{ currentWeekNumber.month }}월 {{ currentWeekNumber.weekNo }}주차</h2></div>
        <div class="header-column"><button class="kb-btn-control" @click="moveWeek(true)"><span class="text">다음 주차</span><i class="icon-next"></i></button></div>

      </div>
      <div class="schedule-body">

        <div class="timetable-icon-wrapper mb-3 kb-mouse-cursor" >
          <div @mouseleave="hideIconDesc">
            <i class="icon-meet" style="margin-right: 4px" @mouseover="showIconDesc('대면집합')" ></i>
            <i class="icon-check" style="margin-right: 4px" @mouseover="showIconDesc('Cloud Now')"></i>
          </div>
          <div v-if="iconDescActive" class="desc-icon-value">{{ iconDesc }}</div>
        </div>

        <div class="schedule-grid">
          <div class="grid-item"></div>
          <div v-for="(date, index) in targetWeek" class="grid-item" :key="index">
            <div class="content" :class="{'off': index === 0 || index === 6}"><span class="text">{{ date.dayofweek }}</span><span class="text">{{ date.day }}</span></div>
          </div>
          <template v-for="(index) in 10" :key="index">
            <div class="grid-item" :class="{'grid-row-last': index === 10}"><div class="content"><span class="text">{{ index }}교시</span><span class="text">{{ (`0${index + 8}`).slice(-2) }}:00</span></div></div>
            <div class="grid-item"></div>
            <div class="grid-item"></div>
            <div class="grid-item"></div>
            <div class="grid-item"></div>
            <div class="grid-item"></div>
            <div class="grid-item"></div>
            <div class="grid-item"></div>
          </template>
          <!-- session-grid -->
          <div class="session-grid">
            <!-- session -->
            <div v-for="(item, index) in renderItems" class="session" :class="getScheduleClasses(item)" :key="index">
              <div class="session-inner">
                <h3 class="session-title"><a href="javascript:" class="title-link" :title="item.objNm">{{ item.objNm }}</a></h3>
                <span class="session-time">{{ getScheduleTime(item) }}</span>
                <span class="session-note">{{ getTutors(item, '-') }}</span>
                <div class="session-icons">
                  <i :class="getScheduleIcon(item)"></i>
                </div>
              </div>
            </div>
          </div>
          <!-- //session-grid -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {learnTimetableSetup} from '@/assets/js/modules/learn/learn-setup';

export default {
  name: 'LearnTimetable',
  components: {},
  props: {
    items: Array
  },
  setup: learnTimetableSetup
};
</script>
