<template>
  <section class="content-section section-ref">
<!--    <header v-if="items.length > 0" class="section-header">-->
    <header class="section-header">
      <h4 class="title">{{ title }}</h4>
      <div class="header-sub">
        <router-link v-if="listRouteName" :to="{name: listRouteName, params: {mode: refMode}, query: {distCrseSn: $route.params.distCrseSn}}" class="more-link">전체보기</router-link>
      </div>
    </header>
<!--    <div v-if="items.length > 0" class="board-list-container">-->
    <div class="board-list-container">
      <ul v-if="items.length > 0" class="board-list">
        <li class="board-list-item" v-for="(item, index) in items" :key="index">
          <article class="board-row">
            <router-link v-if="viewRouteName" :to="{name: viewRouteName, params: { mode : refMode, lrnPostSn: item.lrnPostSn }}" class="board-link"></router-link>
            <a v-else href="javascript:" class="board-link"></a>

            <div v-if="item.topFixYn === 'Y'" class="board-column column-index"><em class="text text-gold">필독</em></div>
            <div v-else class="board-column column-index">{{(index + 1)}}</div>
            <div class="board-column column-file">
              <a href="javascript:" class="file-link" v-if="item.fileAtchYn === 'Y'"><i class="icon-file"></i></a>
            </div>
            <div class="board-column column-title"><span class="title">{{ item.title }}</span>
              <i v-if="isNewIcon(item.regDt)" class="icon-new"></i>
            </div>
            <div class="board-column column-date"><span class="text">{{timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm')}}</span></div>
            <div class="board-column column-hit"><span class="text">{{ item.inqCnt }} 조회</span></div>
          </article>
        </li>
      </ul>
      <ul v-else>
        <div class="tab-panel is-active">
          <div class="learning-status-wrap">
            <div class="kb-table kb-table-bordered learning-tablelist">
              <table>
                <tr><td><div class="content-details text-center">등록된 게시물이 없습니다.</div></td></tr>
              </table>
            </div>
          </div>
        </div>
      </ul>
    </div>
  </section>
</template>

<script>

import {learnRefSetup} from '@/assets/js/modules/learn/learn-setup';

export default {
  name: 'LearnRef',
  components: {},
  props: {
    title: String,
    listRouteName: String,
    viewRouteName: String,
    refMode: String
  },
  setup: learnRefSetup
};
</script>

