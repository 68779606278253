<template>
  <div class="content-section section-board" @click="toggles.cate = false">
    <div class="section-header header-divider">
      <LearnBoardTap
          :items="boards"
      />
    </div>
    <!-- tab-panel#tab01 -->
    <div class="tab-panel is-active" id="tab01">
      <div class="notice-list-container">
        <div class="list-top">
          <div class="top-column">
            <SortButtonSelect
                v-if="categories.length > 0"
                v-model="filters.lrnBoardCateSn"
                v-model:toggle="toggles.cate"
                :options="categories"
                title="전체"
                sequence-key="lrnBoardCateSn"
                name-key="cateNm"
                :width="300"
                :is-all="true"
                :is-num="true"
                @selected="selectCategory"
            />
          </div>
          <div class="top-column">
            <p class="title text-muted me-4">총 {{ paging.totalCount }}건</p>
            <div>
              <div class="kb-form-search">
                <div class="kb-form-search-field">
                  <input v-model.trim="keyword" type="text" class="kb-form-search-input" placeholder="검색" @keyup.enter="searchKeyword">
                  <button class="kb-form-search-btn" @click="searchKeyword"><i class="icon-search">검색</i></button>
                </div>
              </div>
            </div>
            <button class="kb-btn kb-btn-primary ms-3" @click="goWrite"><span class="text">글 작성</span></button>
          </div>
        </div>
        <div v-if="isLoaded" class="board-list-container mb-5">

          <template v-if="items.length > 0">
            <ul class="board-list">
              <li v-for="(item, index) in items" class="board-list-item" :key="index">
                <article class="board-row">
                  <router-link :to="{name: 'LearnBoardView', params: {lrnPostSn: item.lrnPostSn}}" class="board-link"></router-link>
                  <div class="board-column column-index"><em class="text">{{ (paging.totalCount - ((paging.pageNo - 1) * paging.pageSize) - index) }}</em></div>
                  <div class="board-column column-file">
                    <a v-if="item.fileAtchYn === 'Y'"  href="javascript:" class="file-link"><i class="icon-file"></i></a>
                  </div>
                  <div class="board-column column-title"><span class="title">{{ item.title }}</span></div>
                  <div class="board-column column-author "><span class="text">{{ item.lrnerNm }} ({{item.deptNm}} ・ {{ item.jbgdCd && item.jbgdCd.length >= 2 ? item.jbgdCd.substr(0, 2) : '-' }})</span></div>
                  <div class="board-column column-date"><span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</span></div>
                  <div class="board-column column-hit"><span class="text">{{ item.inqCnt }} 조회</span></div>
                </article>
              </li>
            </ul>
            <CommonPaginationFront
                :paging="paging"
                :page-func="pagingFunc"
            />
          </template>
          <div v-else class="search-container">
            <div class="result-empty">
              <img src="@/assets/lxp/images/common/img_empty.png" alt="">
              <p class="text">등록된 게시물이 없습니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- tab-panel#tab02 -->
  </div>
</template>

<script>

import LearnBoardTap from '@/components/learn/board/LearnBoardTap';
import {computed, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {getListFunc, getValueByQuery} from '@/assets/js/ui.init';
import {ACT_GET_LRN_BOARD_CATE_LIST, ACT_GET_LRN_BOARD_LIST} from '@/store/modules/board/board';
import {timestampToDateFormat} from '@/assets/js/util';
import SortButtonSelect from '@/components/common/SortButtonSelect';

export default {
  name: 'LearnBoard',
  components: {SortButtonSelect, CommonPaginationFront, LearnBoardTap},
  props: {
    boards: Array
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const distCrseSn = computed(() => route.params.distCrseSn);
    const isLoaded = ref(false);
    const categories = ref([]);
    const items = ref([]);
    const paging = reactive({
      pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
      pageSize: 10,
      totalCount: 0
    });

    const toggles = reactive({
      cate: false
    });

    const filters = reactive({
      lrnBoardCateSn: getValueByQuery(route.query, 'cate', true, 0),
      search: getValueByQuery(route.query, 'search', false, '')
    });

    const keyword = ref('');

    const getBoardList = () => {
      const distObjSn = route.params.lrnObjDtlDistSn > 0 ? route.params.lrnObjDtlDistSn : 0;
      getListFunc(`board/${ACT_GET_LRN_BOARD_LIST}`, {distObjSn, ...paging, ...filters}, items, paging, () => {
        isLoaded.value = true;
      });
    }

    const getBoardCateList = () => {
      if (distCrseSn.value === '200240') {
        getListFunc(`board/${ACT_GET_LRN_BOARD_CATE_LIST}`, null, categories, null);
      } else {
        categories.value = [];
      }
    }

    const goWrite = () => {
      router.push({name: 'LearnBoardWrite', params: {lrnPostSn: 0}});
    }

    const pagingFunc = (pageNo) => {
      const query = {pageNo};
      if (filters.search) query.search = filters.search;
      if (filters.lrnBoardCateSn > 0) query.cate = filters.lrnBoardCateSn;
      router.push({query});
    }

    const selectCategory = () => {
      const query = {};
      // if (filters.search) query.search = filters.search;
      if (filters.lrnBoardCateSn > 0) query.cate = filters.lrnBoardCateSn;
      if (keyword.value) keyword.value = '';
      router.push({query});
    }

    const searchKeyword = () => {
      const query = {search: keyword.value};
      if (filters.lrnBoardCateSn > 0) query.cate = filters.lrnBoardCateSn;
      router.push({query});
    }

    watch(() => route.params.lrnObjDtlDistSn,() => {
      if (route.name === 'LearnBoard') {
        keyword.value = '';
      }
    })

    watch(() => [route.params.lrnObjDtlDistSn, route.query], () => {
      if (route.name === 'LearnBoard') {
        paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
        filters.search = getValueByQuery(route.query, 'search', false, '');
        filters.lrnBoardCateSn = getValueByQuery(route.query, 'cate', true, 0);
        getBoardList();
      }
    });

    getBoardCateList();
    getBoardList();

    return {
      isLoaded,
      categories,
      items,
      paging,
      keyword,
      filters,
      toggles,
      goWrite,
      searchKeyword,
      pagingFunc,
      selectCategory,
      timestampToDateFormat,

    }

  }
};
</script>
