<template>
  <div class="popup-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-myclass-survey">
      <!-- popup > popup-inner -->
      <div v-if="isReady" class="popup-inner">
        <div>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <div class="survey-header">
              <h4 class="title"><a href="javascript:" class="title-link">{{ svy.svyNm }}</a></h4>
              <div class="meta">
                <span class="text">{{ timestampToDateFormat(svy.svyBgngDt, 'yyyy.MM.dd.w') }} - {{ timestampToDateFormat(svy.svyEndDt, 'yyyy.MM.dd.w') }}</span>
                <span class="text text-muted">{{timestampToDateFormat(svy.svyEndDt, 'yyyy.MM.dd hh:mm')}}까지</span>
              </div>
            </div>

            <div class="survey-container">
              <!-- popup-section:설문안내 -->
              <article v-if="cnVal.length > 0" class="popup-section">
                <header class="section-header">
                  <h4 class="title">설문안내</h4>
                </header>
                <div class="section-content">
                  <div class="segment-box" style="padding: 22px;">
                    <template v-for="(elem, idx) in cnVal" :key="`cn-${idx}`">
                      <p class="text fw-bold">{{ elem }}</p>
                    </template>
                  </div>
                </div>
              </article>

              <!-- popup-section:강의정보 -->
              <article v-if="isCollective" class="popup-section">
                <header class="section-header">
                  <h4 class="title">강의정보</h4>
                </header>
                <div class="section-content">
                  <div class="kb-table kb-table-striped">
                    <table>
                      <colgroup>
                        <col style="width:auto;">
                        <col style="width:250px">
                        <col style="width:250px">
                        <col style="width:250px">
                      </colgroup>
                      <thead>
                      <tr>
                        <th><span class="th-title">강의명</span></th>
                        <th><span class="th-title">챕터명</span></th>
                        <th><span class="th-title">강사</span></th>
                        <th><span class="th-title">일정</span></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><p class="td-text">{{ item.objNm }}</p></td>
                        <td><p class="td-text">{{ item.chapNm ? item.chapNm : '-' }}</p></td>
                        <td><p class="td-text">{{ getTutors(item, '-') }}</p></td>
                        <td><p class="td-text">{{ timestampToDateFormat(item.objBgngDt, 'yyyy.MM.dd') }} ~ {{timestampToDateFormat(item.objEndDt, 'yyyy.MM.dd')}}</p></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </article>

              <!-- survey-group -->
                <section v-for="(part, index) in parts" class="survey-group" :key="index" style="margin-top: 20px; padding-top: 20px">
                  <!-- popup-section:과정 이해도에 대한 설문 -->
                  <article v-if="part.svyPartNm || part.svyPartCn" class="popup-section section-info">
                    <div class="section-content">
                      <div class="segment-box" style="padding: 22px;">
                        <h4 v-if="part.svyPartNm" class="title" style="margin-bottom: 0px">{{ part.svyPartNm }}</h4>
    <!--                        <p v-if="part.svyPartCn" class="text">{{ part.svyPartCn }}</p>-->
                      </div>
                    </div>
                  </article>

                  <!-- 1. 해당 과목에 대한 사전 이해도를 선택해주세요. -->
                  <template v-for="(question, idx) in part.questions" :key="`${index}-${idx}`">
                    <article v-if="question.isShow" class="popup-section section-form" style="margin-top: 25px">
                    <header class="section-header" style="padding-left: 5px">
                      <h1>{{ getQuestionNo(parts, index, idx) }}. {{ question.qstnNm }}</h1>
                      <div v-if="question.invalidated" class="header-sub">
                        <i class="icon-warning"></i>
                        <strong class="text text-red">필수 항목입니다</strong>
                      </div>
                    </header>
                    <div class="section-content">
                      <!-- kb-form-fields -->
                      <div class="kb-form-fields" :class="{'is-invalid': question.invalidated}">
                        <!-- kb-form-row:선택 -->
                        <div v-if="selectTypes.includes(question.qstnKindCdDcd)" class="kb-form-row" style="background-color: white">
                          <div class="">
                            <div class="">
                              <div v-for="(option, i) in question.options" style="padding-left: 20px; padding-bottom: 2px" :key="`${index}-${idx}-${i}`">
                                <input
                                    v-if="question.qstnKindCdDcd === '2020002'"
                                    v-model="question.model"
                                    type="checkbox"
                                    class="kb-form-check-input"
                                    :name="`chk-${index}-${idx}-${i}`"
                                    :id="`chk-${index}-${idx}-${i}`"
                                    :value="option.optNo"
                                    :disabled="!!question.etc"
                                >
                                <input
                                    v-else
                                    v-model="question.model"
                                    type="radio"
                                    class="kb-form-check-input"
                                    :name="`chk-${index}-${idx}`"
                                    :id="`chk-${index}-${idx}-${i}`"
                                    :value="option.optNo"
                                    :disabled="!!question.etc"
                                    @click="chkLink(part)"
                                >

                                <label :for="`chk-${index}-${idx}-${i}`" class="kb-form-check-label"><span class="text">{{ option.optCn }}</span></label>
                              </div>
                            </div>
                          </div>

                          <div v-if="question.etcAnsYn === 'Y'" class="kb-form-column justify-content-end" @click="toggleEtc(question)">
                            <button class="kb-btn-check"><span class="text">{{ question.etc ? '취소' : '기타입력' }}</span></button>
                          </div>
                        </div>

                        <div v-else class="kb-form-row">
                          <div class="kb-form-column">
                            <textarea v-model="question.model" class="kb-form-control"></textarea>
                          </div>
                        </div>

                        <!-- kb-form-row:기타 -->
                        <div v-if="question.etc" class="kb-form-row">
                          <div class="kb-form-column kb-form-column-title">
                            <label class="kb-form-label">
                              <strong class="kb-form-label-text">기타</strong>
                            </label>
                          </div>
                          <div class="kb-form-column column-whole">
                            <input v-model.trim="question.etcAns" type="text" class="kb-form-control" placeholder="직접 입력">
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  </template>
                </section>
            </div>
          </div>
          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="submitSurvey"><span class="text">제출</span></button>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
</template>

<script>

import {learnSvySetup} from '@/assets/js/modules/learn/learn-svy-setup';

export default {
  name: 'LearnSurvey',
  props: {
    active: Boolean,
    item: Object,
    svy: Object,
    hasCallback: Boolean
  },
  emits: ['update:active', 'renewEnd'],
  setup: learnSvySetup
};
</script>
