import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, ref} from 'vue';
import {
  getTutors,
  isSurvey,
  lrnObjTyCds,
} from '@/assets/js/modules/learn/learn-common';
import {
  ACT_GET_SVY_DIST_PART_LIST,
  ACT_GET_SVY_RSLT_LIST,
  ACT_INSERT_SVY_RSLTS,
} from '@/store/modules/survey/survey';
import {
  getItem, getItems,
  getResult,
  isSuccess,
  lengthCheck,
  timestampToDateFormat,
} from '@/assets/js/util';
import {getListFunc} from '@/assets/js/ui.init';

export const learnSvySetup = (props, {emit}) => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter()
  const {showMessage, showConfirm, showLoading, hideLoading} = useAlert();
  const isMobile = computed(() => store.state.auth.isMobile);
  const parts = ref([]);
  const isReady = ref(false);
  const isLoading = ref(false);
  const isCollective = computed(() => props.item.lrnObjTyCd === lrnObjTyCds.Collective);
  const cnVal = computed(() => {
    if (props.svy.svyCn && props.svy.svyCn.trim()) {
      return props.svy.svyCn.trim().split(/\n/g)
    }
    return [];
  });
  const svyAnswers = ref([]);
  const cntstCrseYn = ref(false);
  const uniqueLinkQstn = ref([]);
  const excludedLinkQstn = ref([]);

  showLoading();

  const toggleEtc = (question) => {
    question.etc = !question.etc;
    if (question.etc) {
      if(question.qstnKindCdDcd === '2020002') {
        question.model = [];
      } else {
        question.model = ''
      }
    } else {
      question.etcAns = '';
    }
  }

  const validateQuestion = () => {
    let invalidateCount = 0;

    parts.value.forEach(x => {
      x.questions.forEach(y => {
        // 초기화
        y.invalidated = false;
        if(y.ansEsntlYn === 'Y' && y.isShow){
          if(y.qstnKindCdDcd === '2020002'){
            if(y.model.length === 0 && y.etcAns === ''){
              invalidateCount++;
              y.invalidated = true;
            }
          }else{
            if((y.model === null || y.model === undefined || y.model === '') && y.etcAns === ''){
              invalidateCount++;
              y.invalidated = true;
            }
          }
        }
      });
    });

    if(invalidateCount > 0){
      showMessage('설문 필수 항목들이 존재합니다.<br>해당 항목들을 답변해주세요.');
    }

    return invalidateCount === 0;
  }

  const getQuestionNo = (parts, index, idx) => {
    let questionNo = 0;

    for(let i = 0; i < index; i++) {
      questionNo += parts[i].questions.length;
    }
    questionNo += (idx + 1);
    return questionNo;
  }

  const getQuestionAnswer = (question) => {
    if(question.qstnKindCdDcd === '2020002'){
      return question.model.join(',');
    }
    return question.model;
  }

  const getAnswers = () => {
    const answers = [];
    parts.value.forEach(x => {
      answers.push(...x.questions.map(y => ({
        svyRsltSn: y.svyRsltSn || 0,
        qstnNo: y.qstnNo,
        ansOptNo: getQuestionAnswer(y),
        etcAns: y.etcAns
      })));
    });

    return answers;
  }

  const submitSurvey = () => {
    if(isLoading.value) return;
    isLoading.value = true;

    if(validateQuestion()){

      const params = { results: getAnswers() }
      if(isSurvey(props.item.lrnObjTyCd)) params.lrnRsltSn = props.item.lrnRsltSn;

      showConfirm({
        title: '설문제출',
        text: '설문을 제출하시겠습니까?',
        callback: () => {
          store.dispatch(`survey/${ACT_INSERT_SVY_RSLTS}`, {distSvySn: props.svy.distSvySn, params}).then(res => {
            if(isSuccess(res)){
              if (cntstCrseYn.value) {
                closeModal();

                if(props.item.splCrseTyCdDcd=== '2066030'){
                  showMessage('수강신청이 완료되었습니다.');
                  router.push({name: 'TrainGlobal'});
                }else {
                  showMessage('수강신청이 완료되었습니다.');
                  router.push({name: 'ApplyStatus'});
                }
              } else {
                if (!props.hasCallback) showMessage('설문이 제출되었습니다.');
                closeModal();
                emit('renewEnd');
              }
            }else{
              showMessage(getResult(res).message);
              isLoading.value = false;
            }
          }).catch(e => {
            console.error(e);
            showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            isLoading.value = false;
          });
        },
        closeCallback: () => {
          isLoading.value = false;
        }
      });
    }else{
      isLoading.value = false;
    }
  }

  const clickBack = () => {
    if (props.item.cntstCrseYn === 'Y' || props.item.splCrseTyCdDcd ==='2066030') {
      if (props.item.svyRsltCnt > 0) {
        router.push({
          name: 'ApplyTrainView',
          params: {distCrseSn: props.item.distCrseSn}
        });
      }
    } else {
      const currentQuery = {...route.query};
      delete currentQuery.modal;
      router.push({query: currentQuery});
    }
  }

  const closeModal = () => {
    if (isMobile.value) {
      clickBack();
    } else {
      emit('update:active', false);
    }
  }

  const checkSurveyResult = callback => {
    store.dispatch(`survey/${ACT_GET_SVY_RSLT_LIST}`, props.svy.distSvySn).then(res => {
      if (lengthCheck(res)) {
        if (getItem(res).cntstCrseYn && getItem(res).cntstCrseYn == 'Y') {
          cntstCrseYn.value = true;
          svyAnswers.value = getItems(res);
          // console.log(svyAnswers.value);
          callback();
        } else {
          showMessage({
            title: '참여완료',
            text: '이미 설문에 참여하셨습니다.'
          });
          emit('renewEnd');
          hideLoading();
          closeModal();
        }
      } else {
        callback()
      }
    }).catch(() => {
      hideLoading();
    });
  }

  const getSurveyParts = () => {
    getListFunc(`survey/${ACT_GET_SVY_DIST_PART_LIST}`, props.svy.distSvySn, parts, null, () => {
      parts.value.forEach(x => {
        if(x.questions) x.questions = x.questions.map(y => ({...y, model: (y.qstnKindCdDcd === '2020002' ? [] : ''), etcAns: ''}));
      });
      isReady.value = true;
      hideLoading();
      uniqueLinkQstn.value = [];
      parts.value.forEach(x => {
        if(x.questions){
          x.questions.forEach(y => {
            const target = svyAnswers.value.find(z => y.qstnNo === z.qstnNo);
            const ans = [];
            if(target) {
              // console.log(target);
              y.svyRsltSn = target.svyRsltSn;
              if (target.qstnKindCdDcd === '2020002') {
                if (target.ansOptNo) {
                  let splArr = target.ansOptNo.split(',');
                  ans.push(...splArr);
                  y.model = ans;
                }
              } else {
                y.model = target.ansOptNo;
              }
            }

            if (y.options) {
              y.options.forEach(v => {
                if (v.linkQstnSn) {
                  v.linkQstnSn.split(',').forEach(w => {
                    uniqueLinkQstn.value.push(w);
                  });
                }
              });
            }
          });
        }
      });
      // console.log(uniqueLinkQstn.value);
      setTimeout(() => {
        initPart();
      }, 100);
      // console.log(parts.value);
    })
  }

  const isLinkQstn = (elem) => {
    if (uniqueLinkQstn.value.filter(x => x === elem.svyQstnSn + '').length > 0) {
      return true;
    }
    return false;
  };

  const initPart = () => {
    parts.value.forEach(x => {
      if(x.questions) {
        x.questions.forEach(y => {
          y.isLink = isLinkQstn(y);
          y.isShow = isLinkQstn(y) ? false : true;
        });
      }
    });
  }

  const chkLink = async (opt) => {
    excludedLinkQstn.value = [];
    setTimeout(() => {
      parts.value[opt.svyPartNo - 1].questions.forEach(x => {
        x.options.forEach(y => {
          if (x.model === y.optNo) {
            const linkQstn = y.linkQstnSn ? y.linkQstnSn.split(',') : [];
            excludedLinkQstn.value.push(...linkQstn);
          }
        });
      });
      // console.log(excludedLinkQstn.value);
    }, 100);

    setTimeout(() => {
      parts.value[opt.svyPartNo - 1].questions.forEach(y => {
        if (excludedLinkQstn.value.includes(y.svyQstnSn + '')) {
          y.isShow = true;
          y.ansEsntlYn = 'Y';
        } else {
          y.isShow = isLinkQstn(y) ? false : true;
          if (isLinkQstn(y)) {
            y.model = y.qstnKindCdDcd === '2020002' ? [] : '';
          }
        }
      });
    }, 100);
  };

  if(props.svy && props.svy.distSvySn > 0){
    checkSurveyResult(getSurveyParts);
  }

  return {
    isReady,
    isCollective,
    parts,
    getQuestionNo,
    closeModal,
    toggleEtc,
    submitSurvey,
    getTutors,
    timestampToDateFormat,
    cnVal,
    chkLink,
    selectTypes: ['2020001', '2020002', '2020003', '2020005', '2020006']
  }
}
