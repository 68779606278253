<template>
  <main class="kb-main" :id="mainId">
    <lxp-main-header :show-back="true" :go-back-func="goTrainMain">
      <template v-slot:inner>
<!--        <div class="header-column">-->
<!--          <button class="page-help kb-btn-popup" @click="passModal = !passModal"><span class="text">과정 및 차수 수료조건</span><i class="icon-help"></i></button>-->
<!--        </div>-->
      </template>
      <template v-slot:outer>
        <LearnRoute
            :is-init="isInit"
            :collective-cnt="collectiveCnt"
            :board-objects="boardObjects"
        />
      </template>
    </lxp-main-header>

    <div class="main-content main-component">
      <LearnInfo
          v-if="isInit && infoModes.includes(modeIdx)"
          :params="params"
          :learns="objectResults"
      />

      <template v-if="modeIdx === 0">
        <LearnScheduled
            :items="objectResults"
            :current-date="currentDate"
            @openLearn="openLearn"
        />

        <LearnStatus
            v-if="statuses.length > 0"
            :items="statuses"
            :result-param="resultParam"
        />

        <LearnRef
            title="공지사항"
            refMode="notice"
            list-route-name="LearnBulletin"
            view-route-name="LearnBulletinView"
        />

        <LearnRef
            title="자료실"
            refMode="lib"
            list-route-name="LearnBulletin"
            view-route-name="LearnBulletinView"
        />
      </template>

      <LearnIndex
          v-else-if="modeIdx === 1"
          :params="params"
          :items="objectResults"
          :current-date="currentDate"
          @openLearn="openLearn"
      />

      <LearnBoard
          v-else-if="modeIdx === 2"
          :boards="boardObjects"
      />

      <LearnTimetable
          v-else-if="modeIdx === 3"
          :items="objectResults"
      />
    </div>

    <LearnEvaluation
        v-if="windowActive && targetLearnType === 'evl'"
        :evl="targetAdded"
        v-model:active="windowActive"
        :is-answer="windowOptions.answer"
        @renewInit="renewStartDt"
        @renewEnd="renewEndDt"
    />
    <LearnSurvey
        v-else-if="windowActive && (targetLearnType === 'svy')"
        v-model:active="windowActive"
        :item="targetItem"
        :svy="targetAdded"
        @renewEnd="getLearnObjectResult"
    />
    <LearnCms
        v-else-if="windowActive && targetLearnType === 'cms'"
        v-model:active="windowActive"
        :item="targetItem"
        :cms="targetAdded"
        :memory-limit="7"
        @renewEnd="getLearnObjectResult"
        :use-cold-call="params.coldCallUseYn === 'Y'"
        :lrn-psblt-hr-div="params.lrnPsbltHrDiv"
    />
  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import LearnRoute from '@/components/learn/LearnRoute';
import LearnStatus from '@/components/learn/LearnStatus';
import LearnInfo from '@/components/learn/LearnInfo';
import LearnScheduled from '@/components/learn/LearnScheduled';
import LearnRef from '@/components/learn/LearnRef';
import LearnIndex from '@/components/learn/LearnIndex';
import LearnTimetable from '@/components/learn/LearnTimetable';
import LearnEvaluation from '@/components/learn/window/LearnEvaluation';
import LearnSurvey from '@/components/learn/window/LearnSurvey';
import LearnCms from '@/components/learn/window/LearnCms';
import {learnMainSetup} from '@/assets/js/modules/learn/learn-setup';
import LearnBoard from '@/components/learn/LearnBoard';

export default {
  name: 'LearnMain',
  components: {
    LearnBoard,
    LearnCms,
    LearnSurvey,
    LearnEvaluation,
    LearnTimetable, LearnIndex, LearnRef, LearnScheduled, LearnInfo, LearnStatus, LearnRoute, LxpMainHeader},
  setup: learnMainSetup
}
</script>
