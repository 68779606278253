<template>
  <div v-if="evlTestCount > 0" class="content-section section-status">
    <div class="section-header">
      <div class="nav">
        <button class="kb-nav-link is-active" ><span class="title">모의테스트</span></button>
      </div>
      <div v-if="tab === 'today'" class="header-sub"><span class="subtext text-gold">{{ timestampToDateFormat(currentDateStr, 'full-kr') }}</span></div>
    </div>
    <div class="tab-panel is-active">
      <div class="learning-status-wrap">
        <div class="kb-table kb-table-bordered learning-tablelist">
          <table>
            <colgroup>
              <col style="width:auto" />
              <col style="width:190px" />
              <col style="width:auto" />
              <col style="width:120px" />
              <col style="width:140px" />
              <col style="width:162px" />
            </colgroup>
            <tbody>
            <template v-for="(item, index) in items" :key="index">
              <tr v-if="!isReferenceLearn(item.lrnObjTyCd) && isLearnObjectShow(item) && item.objSn === 4888">
                <td>
                  <div class="item-content">
                    <div class="content-symbol"><i :class="getSymbolType(item.lrnObjTyCd)"></i></div>
                    <div class="content-details">
                      <h5 class="content-title"><a href="javascript:" class="title-link">{{ item.objNm }}</a></h5>
                      <p class="content-text"><span class="text">{{ item.lrnObjTyNm }}</span></p>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="item-date">
                    <span v-if="item.objBgngDt && item.objEndDt" class="text" v-html="`${getObjectDt(item, 'Bgng')} ~<br>${getObjectDt(item, 'End')}`"></span>
                    <span v-else class="text">-</span>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <div class="item-actions">
                    <a v-if="item.fnshYn === 'Y'" href="javascript:" class="kb-btn kb-btn-primary" @click="openLearnWindow(index, 'N')"><strong class="text">{{ getButtonText(item) }}</strong></a>
                    <a v-else-if="canDo(item, currentDate)" href="javascript:" class="kb-btn kb-btn-primary" @click="openLearnWindow(index, 'N')"><strong class="text">{{ getButtonText(item) }}</strong></a>
                    <a v-else href="javascript:" class="kb-btn kb-btn-primary" disabled><strong class="text">{{ getButtonText(item) }}</strong></a>
                  </div>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="content-section section-status mt-7 section-margin">
    <div class="section-header">
      <div class="nav">
        <button class="kb-nav-link" :class="{ 'is-active': tab === 'today' }" @click="selectTab('today')"><span class="title">오늘 학습만 보기</span></button>
        <button class="kb-nav-link" :class="{ 'is-active': tab === 'all' }" @click="selectTab('all')"><span class="title">전체 학습목차 보기</span></button>
      </div>
      <div v-if="tab === 'today'" class="header-sub"><span class="subtext text-gold">{{ timestampToDateFormat(currentDateStr, 'full-kr') }}</span></div>
    </div>
    <!-- tab-panel#tab01 -->
    <!-- [!DEV] tab-panel is-active 탭 활성화 -->
    <div class="tab-panel is-active">
      <div class="learning-status-wrap">
        <div v-if="renderCount > 0" class="kb-table kb-table-bordered learning-tablelist">
          <table>
            <colgroup>
              <col style="width:auto" />
              <col style="width:190px" />
              <col style="width:auto" />
              <col style="width:120px" />
              <col style="width:140px" />
              <col style="width:162px" />
            </colgroup>
            <tbody>
            <template v-for="(item, index) in items" :key="index">
              <tr v-if="!isReferenceLearn(item.lrnObjTyCd) && isLearnObjectShow(item) && item.objSn !== 4888">
                <td>
                  <div class="item-content">
                    <div class="content-symbol"><i :class="getSymbolType(item.lrnObjTyCd)"></i></div>
                    <div class="content-details">
                      <h5 class="content-title"><a href="javascript:" class="title-link">{{ item.objNm }}</a></h5>
                      <p class="content-text"><span class="text">{{ item.lrnObjTyNm }} {{ item.evlProgRate > 0 ? ' - 가능진도율 : '.concat(item.evlProgRate,'%') : ''}}</span></p>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="item-date">
                    <span v-if="item.objBgngDt && item.objEndDt" class="text" v-html="`${getObjectDt(item, 'Bgng')} ~<br>${getObjectDt(item, 'End')}`"></span>
                    <span v-else class="text">-</span>
                  </div>
                </td>
                <td><div class="item-type"><span class="text">{{ item.chapNm ? item.chapNm : ''}}</span></div></td>
                <td><div class="item-teacher"><span class="text">{{getTutors(item)}}</span></div></td>
                <!-- 출석 / 결석 / 응시전 / 응시완료 / 참여전 / 제출전  -->
                <td><div class="item-status"><span class="text" v-html="getScoreUnit(item, params.coldCallUseYn)"></span></div></td>
                <td>
                  <!-- onclass 분기 -->
                  <div v-if="item.lrnObjTyCd === '100008' || item.lrnObjTyCd === '100026' || item.lrnObjTyCd === '100020'" class="item-actions">
                    <a v-if="((new Date(item.objBgngDt).getTime() - new Date().getTime())/1000/60 < 10) && item.objEndDt > new Date().getTime() && item.objEndYn === 'N'"
                       href="javascript:" class="kb-btn kb-btn-primary" @click="enterNowClass(item)"><strong class="text">{{ getButtonText(item) }}</strong></a>
                    <a v-else href="javascript:" class="kb-btn kb-btn-primary" disabled><strong class="text">{{ getButtonText(item) }}</strong></a>
                  </div>
                  <div v-else class="item-actions">
                    <a v-if="item.fnshYn === 'Y'" href="javascript:" class="kb-btn kb-btn-light-gray" @click="openLearnWindow(index, 'Y')"><strong class="text">{{ getButtonText(item) }}</strong></a>
                    <a v-else-if="canDo(item, currentDate)" href="javascript:" class="kb-btn kb-btn-primary" @click="openLearnWindow(index, 'N')"><strong class="text">{{ getButtonText(item) }}</strong></a>
                    <a v-else href="javascript:" class="kb-btn kb-btn-light-gray" disabled><strong class="text">{{ getButtonText(item) }}</strong></a>
                  </div>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>

        <div v-else class="contents-empty">
          <img src="@/assets/lxp/images/common/img_empty.png" alt=""/>
          <p class="text">오늘 예정된 학습이 없습니다.</p>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 30px"></div>
  </div>
  <CloudNowPledgeModal v-if="applyModal"
                       v-model="applyModal"
                       :distCrseSn="pledge.distCrseSn"
                       :lrnerId="pledge.lrnerId"
                       :lrnerNm="pledge.lrnerNm"/>
</template>

<script>
import {learnIndexSetup} from '@/assets/js/modules/learn/learn-setup';
import CloudNowPledgeModal from "@/components/cloudNow/CloudNowPledgeModal";

export default {
  name: 'LearnIndex',
  components: {CloudNowPledgeModal},
  props: {
    params: Object,
    items: Array,
    currentDate: Object
  },
  emits: ['openLearn'],
  setup: learnIndexSetup
};
</script>
