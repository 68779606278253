<template>
  <section class="content-section section-status">
    <header class="section-header">
      <h4 class="title">나의 학습 현황</h4>
       <div class="header-sub">
         <span v-if="resultParam.fnshYn === 'Y'" class="subtext">총점 기준 : {{ resultParam.totScore ? resultParam.totScore : 0 }} / {{ resultParam.fnshTot }}점</span>
         <span v-else class="subtext text-red">총점 계산중</span>
       </div>
    </header>
    <div class="learning-status-wrap">
      <ul class="divider-list">
        <template v-for="(item, index) in items" :key="index">
          <li v-if="checkUseObjects(item)" class="divider-item">
            <div class="item-row">
              <div class="item-symbol">
                <i :class="getFinishSymbolType(item)"></i>
              </div>
              <div class="item-content">
                <h5 class="content-title"><a href="javascript:" class="title-link">{{ getTargetName(item) }}</a></h5>
                <p v-if="item.fnshCateCdDcd === fnshCateCdDcds.Evaluation" class="content-text">{{ item.evlTyNm }}</p>
              </div>
              <div class="item-type" style="flex: 0 0 120px;">
                <span class="text">학습 콘텐츠 : {{ getUseObjectCount(item) }}</span>
              </div>
              <div class="item-evaluation">
                <span class="text">&nbsp;</span>
              </div>
              <div class="item-status">
                <span class="text text-primary">{{getCurrentValue(item)}} / {{ getTotCount(item) }}{{getTargetUnit(item.fnshCondNm)}}</span>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </section>
</template>

<script>
import {learnStatusSetup} from '@/assets/js/modules/learn/learn-setup';

export default {
  name: 'LearnStatus',
  components: {},
  props: {
    items: Array,
    resultParam: Object,
  },
  setup: learnStatusSetup
};
</script>
