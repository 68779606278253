import {useRoute, useRouter} from 'vue-router';
import {computed, onMounted, onUnmounted, reactive, ref, watch} from 'vue';
import {getListFunc, getValueByQuery, initEditor} from '@/assets/js/ui.init';
import {
  ACT_DELETE_LRN_BOARD, ACT_GET_LRN_BOARD, ACT_GET_LRN_BOARD_CATE_LIST,
  ACT_GET_LRN_BOARD_LIST, ACT_INSERT_LRN_BOARD,
  ACT_UPDATE_LRN_BOARD,
} from '@/store/modules/board/board';
import {
  collectionCheck, convertToStorageBytes,
  getItem,
  isSuccess,
  lengthCheck, setParams,
  timestampToDateFormat,
} from '@/assets/js/util';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import navigationUtils from '@/assets/js/navigationUtils';
import {serverError, serverUploadError} from '@/assets/js/modules/error/error-message';
import ApiService from '@/assets/js/api.service';

export const learnBoardSetup = (props) => {
  const route = useRoute();
  const router = useRouter();

  const distCrseSn = computed(() => route.params.distCrseSn);

  const isLoaded = ref(false);
  const categories = ref([]);
  const items = ref([]);
  const paging = reactive({
    pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
    pageSize: 10,
    totalCount: 0
  });

  const toggles = reactive({
    tab: false,
    cate: false
  });

  const currentObjectNm = computed(() => {
    const currentSn = route.params.lrnObjDtlDistSn > 0 ? parseInt(route.params.lrnObjDtlDistSn) : 0;
    if (props.boards && props.boards.length > 0 && currentSn > 0) {
      const target = props.boards.find(x => x.lrnObjDtlDistSn === currentSn);
      return target ? target.objNm : '';
    }
    return '';
  });

  const currentCateNm = computed(() => {
    if (categories.value.length > 0 && filters.lrnBoardCateSn > 0) {
      const target = categories.value.find(x => x.lrnBoardCateSn === filters.lrnBoardCateSn);
      return target ? target.cateNm : '전체';
    }
    return '전체';
  });

  const filters = reactive({
    lrnBoardCateSn: getValueByQuery(route.query, 'cate', true, 0),
    search: getValueByQuery(route.query, 'search', false, ''),
  });
  const keyword = ref('');

  const getBoardList = () => {
    const distObjSn = route.params.lrnObjDtlDistSn > 0 ? route.params.lrnObjDtlDistSn : 0;
    getListFunc(`board/${ACT_GET_LRN_BOARD_LIST}`, {distObjSn, ...paging, ...filters}, items, paging, () => {
      isLoaded.value = true;
    });
  }

  const getBoardCateList = () => {
    if (distCrseSn.value === '200240') {
      getListFunc(`board/${ACT_GET_LRN_BOARD_CATE_LIST}`, null, categories, null);
    } else {
      categories.value = [];
    }
  }
  const pagingFunc = (pageNo) => {
    const query = {pageNo};
    if (filters.search) query.search = filters.search;
    if (filters.lrnBoardCateSn > 0) query.cate = filters.lrnBoardCateSn;
    router.push({query});
  }

  const selectCategory = () => {
    const query = {};
    // if (filters.search) query.search = filters.search;
    if (filters.lrnBoardCateSn > 0) query.cate = filters.lrnBoardCateSn;
    if (keyword.value) keyword.value = '';
    router.push({query});
  }

  const searchKeyword = () => {
    const query = {search: keyword.value};
    if (filters.lrnBoardCateSn > 0) query.cate = filters.lrnBoardCateSn;
    router.push({query});
  }

  const goWrite = () => {
    router.push({name: 'LearnBoardWrite', params: {lrnPostSn: 0}});
  }

  watch(() => route.params.lrnObjDtlDistSn,() => {
    if (route.name === 'LearnBoard') {
      keyword.value = '';
    }
  })

  watch(() => [route.params.lrnObjDtlDistSn, route.query], () => {
    if (route.name === 'LearnBoard') {
      paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
      filters.search = getValueByQuery(route.query, 'search', false, '');
      filters.lrnBoardCateSn = getValueByQuery(route.query, 'cate', true, 0);
      getBoardList();
    }
  });

  getBoardCateList();
  getBoardList();

  return {
    isLoaded,
    categories,
    items,
    paging,
    keyword,
    filters,
    toggles,
    goWrite,
    pagingFunc,
    searchKeyword,
    selectCategory,
    currentObjectNm,
    currentCateNm,
    timestampToDateFormat
  }
}


export const learnBoardTapSetup = (props, {emit}) => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const isMobile = computed(() => store.state.auth.isMobile);
  const currentLrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn ? parseInt(route.params.lrnObjDtlDistSn) : 0);

  const closeToggle = () => {
    emit('update:toggle', false);
  }

  const goBoardObject = lrnObjDtlDistSn => {
    if (currentLrnObjDtlDistSn.value !== lrnObjDtlDistSn) {
      router.push({params: {lrnObjDtlDistSn}});
    }

    if (isMobile.value) closeToggle();
  }

  return {
    currentLrnObjDtlDistSn,
    goBoardObject,
    closeToggle
  }

}

export const learnBoardWriteSetup = () => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const {showLoading, hideLoading, showConfirm, showMessage} = useAlert();

  const session = computed(() => store.state.auth.session);
  const distCrseSn = computed(() => route.params.distCrseSn);
  const distObjSn = computed(() => route.params.lrnObjDtlDistSn > 0 ? parseInt(route.params.lrnObjDtlDistSn) : 0);
  const lrnPostSn = computed(() => route.params.lrnPostSn ? parseInt(route.params.lrnPostSn) : 0);
  const isInApp = ref(navigator && navigator.appVersion && navigator.appVersion.indexOf('eHRD_app') > -1 && navigationUtils.iOS());
  const categories = ref([]);
  const pageEditor = ref(null);
  const editorIframe = ref(null);

  const params = reactive({
    title: '',
    cn: '',
    lrnBoardCateSn: 0
  });

  const currentCateNm = computed(() => {
    if (categories.value.length > 0 && params.lrnBoardCateSn > 0) {
      const target = categories.value.find(x => x.lrnBoardCateSn === params.lrnBoardCateSn);
      return target ? target.cateNm : '전체';
    }
    return '전체';
  });

  const attachFile = ref(null);
  const attachments = ref([]);
  const removedAttachments = ref([]);

  const toggles = reactive({
    extra: false,
    cate: false
  });

  const getBoardCateList = () => {
    if (distCrseSn.value === '200240') {
      getListFunc(`board/${ACT_GET_LRN_BOARD_CATE_LIST}`, null, categories, null);
    } else {
      categories.value = [];
    }
  }

  const attachedFile = () => {
    if (attachFile.value.files && attachFile.value.files.length > 0) {
      if (attachments.value.length + attachFile.value.files.length > 3) {
        showMessage('파일첨부는 최대 3개까지 가능합니다.');
      } else {
        for (let i = 0; i < attachFile.value.files.length; i++) {
          const ext = attachFile.value.files[i].name.substring(attachFile.value.files[i].name.lastIndexOf('.') + 1);
          attachments.value.push({
            lrnPostFileAtchSn: 0,
            fileNm: attachFile.value.files[i].name,
            fileSz: attachFile.value.files[i].size,
            fileTyCdDcd: ext,
            fileTyNm: ext,
            binary: attachFile.value.files[i],
          });
        }
      }
    }
    attachFile.value.value = null;
  }

  const removeAttachment = index => {
    if (attachments.value[index].lrnPostFileAtchSn > 0) removedAttachments.value.push(attachments.value[index]);
    attachments.value.splice(index, 1);
  };


  let editorInstance = null;
  const tryCount = ref(0);

  const setInitTableTemplate = (editor) => {
    editorInstance = editor;
    editor.setData(params.cn);
    hideLoading();
  }

  const getSaveAction = () => {
    const adds = attachments.value.filter(x => !x.lrnPostFileAtchSn && x.itnPath);
    adds.forEach(x => {delete x['binary'];})
    const saveParams = lrnPostSn.value > 0 ? {
      lrnPostSn: lrnPostSn.value,
      params: {
        ...params,
        files: adds,
        removedFiles: removedAttachments.value
      }
    } : {
      ...params,
      distCrseSn: distCrseSn.value,
      distObjSn: distObjSn.value,
      files: adds,
      removedFiles: removedAttachments.value
    }

    return (lrnPostSn.value > 0 ?
        store.dispatch(`board/${ACT_UPDATE_LRN_BOARD}`, saveParams) :
        store.dispatch(`board/${ACT_INSERT_LRN_BOARD}`, saveParams));
  }

  const deleteWrite = () => {
    // board 일때만 삭제
    store.dispatch(`board/${ACT_DELETE_LRN_BOARD}`, lrnPostSn.value).then(res => {
      if(isSuccess(res)){
        showMessage(`학습게시판 게시물이 삭제되었습니다.`);
        // router.push({name: `${targetName.value}Sub`, query: currentQuery.value});
      }else{
        showMessage(serverError);
      }
    }).catch(e => {
      console.error(e);
      showMessage(serverError);
    });
  }

  const uploadLink = `/v1/app/learns/boards/object-${distObjSn.value}/upload`;
  const attachmentLink = `/v1/app/learns/boards/object-${distObjSn.value}-attachments/upload`;

  const saveWrite = async () => {
    try {

      for (let i = 0; i < attachments.value.length; i++) {
        if (attachments.value[i].binary) {
          const uploadRes = await ApiService.upload(attachmentLink, [attachments.value[i].binary]);
          if (uploadRes && uploadRes.path) {
            attachments.value[i].itnPath = uploadRes.path;
            attachments.value[i].etnPath = uploadRes.path;
          }else{
            showMessage(serverUploadError);
            return;
          }
        }
      }
      const res = await getSaveAction();
      hideLoading();
      if(isSuccess(res)){
        showMessage({
          title: '학습 게시판',
          text: `게시물이 ${lrnPostSn.value > 0 ? '수정' : '등록'}되었습니다.`
        });

        await router.push({name: 'LearnBoard'});
      }else{
        showMessage(serverError);
      }
    } catch (e) {
      console.error(e);
      hideLoading();
      showMessage(serverError);
    }
  }

  const validateContents = () => {
    params.cn = editorInstance.getData();

    let validated = false;
    let msg = '';

    if(!params.title) {
      msg = '제목을 입력해주세요.';
    } else if(!params.cn) {
      msg = '내용을 입력해주세요.';
    } else {
      validated = true;
    }

    if(!validated ) {
      showMessage({
        title: '학습게시판',
        text: msg
      });
    }

    return validated;
  }

  const clickDelete = () => {
    showConfirm({
      title: '학습게시판',
      text : `학습게시판 게시물을 삭제 하시겠습니까?`,
      callback: deleteWrite
    });
  }


  const clickSave = () => {
    if(validateContents()) {
      showConfirm({
        title: '학습게시판',
        text : `학습게시판 게시물을 ${lrnPostSn.value > 0 ? '수정' : '등록'} 하시겠습니까?`,
        callback: saveWrite
      });
    }
  }

  const exitWrite = () => {
    showConfirm({
      text : '페이지를 나가면<br>작성중이던 내용은 삭제됩니다.',
      callback: () => {
        router.push({name: 'LearnBoard'});
      }
    });
  }

  const goBackWithErrorAlert = () => {
    hideLoading();
    showMessage('잘못된 접근입니다.');
    router.push({name: 'LearnBoard'});
  }

  showLoading();
  getBoardCateList();

  onMounted(() => {
    // 입과자 테스트 일단 처리 안함.
    if (lrnPostSn.value > 0) {
      store.dispatch(`board/${ACT_GET_LRN_BOARD}`, lrnPostSn.value).then(boardRes => {
        if(lengthCheck(boardRes)){
          const item = getItem(boardRes);
          if(session.value.lrnerId === item.lrnerId && item.distObjSn === distObjSn.value){
            setParams(params, item);
            if (collectionCheck(item.files, 'lrnPostFileAtchSn')) {
              attachments.value = item.files;
            }

            initEditor(pageEditor.value, editorIframe.value, tryCount.value, setInitTableTemplate, isInApp.value, uploadLink);
          } else{
            // 권한이 없는 멘토링 일지 접근
            goBackWithErrorAlert();
          }
        } else {
          // 권한이 없는 멘토링 일지 접근
          goBackWithErrorAlert();
        }
      });
    } else {
      initEditor(pageEditor.value, editorIframe.value, tryCount.value, setInitTableTemplate, isInApp.value, uploadLink);
    }
  });

  onUnmounted(() => {
    if(editorInstance !== null) {
      editorInstance.destroy();
    }
  });

  return {
    pageEditor,
    editorIframe,
    lrnPostSn,
    isInApp,
    params,
    currentCateNm,
    toggles,
    categories,
    clickDelete,
    clickSave,
    exitWrite,
    isMobile: navigationUtils.any(),


    // 첨부 파일
    attachFile,
    attachments,
    attachedFile,
    removeAttachment,
    convertToStorageBytes,
  }
}
