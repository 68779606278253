<template>
  <section v-if="scheduled.length > 0" class="content-section section-today">
    <header class="section-header">
      <h4 class="title">오늘 예정된 학습</h4>
    </header>
    <!-- today-learning-container -->
    <div class="today-learning-container">
      <div class="today-learning-row">
        <!-- course-card -->
          <swiper
              :slides-per-view="(scheduled.length > 2 ? 3 : scheduled.length)"
              :loop="false"
              :navigation="{
                prevEl: '.kb-btn-kb-nav-schedule-prev',
                nextEl: '.kb-btn-kb-nav-schedule-next'
              }"
          >
            <swiper-slide v-for="(item, index) in scheduled" :key="index">
              <!--  온클래스 분기  -->
              <div v-if="item.lrnObjTyCd === '100008' || item.lrnObjTyCd === '100026' || item.lrnObjTyCd === '100020'" class="course-card kb-mouse-cursor"
                   :class="{ 'is-active' : ((new Date(item.objBgngDt).getTime() - new Date().getTime())/1000/60 < 10) && item.objEndDt > new Date().getTime() && item.objEndYn === 'N' }"
                   @click="enterNowClass(item)">
                <div class="course-content">
                  <span class="content-date">{{ timestampToDateFormat(item.objBgngDt, 'hh:mm') }} - {{ timestampToDateFormat(item.objEndDt, 'hh:mm') }}</span>
                  <p class="content-title">{{ item.objNm }}</p>
                  <div class="badge" v-if="((new Date(item.objBgngDt).getTime() - new Date().getTime())/1000/60 < 10) && item.objEndDt > new Date().getTime() && item.objEndYn === 'N'">
                    <span class="badge-text">NOW</span>
                  </div>
                </div>
                <div class="course-type">
                  <span class="text">{{ item.lrnObjTyNm }}{{ item.lrnObjTyCd === lrnObjTyCds.Evaluation ? ` - ${item.evlTyNm}` : '' }}</span>
                </div>
              </div>

              <div v-else class="course-card kb-mouse-cursor" :class="{ 'is-active' : getClassNow(item.objBgngDt, item.objEndDt) }" @click="openLearn(item)">
                <div class="course-content">
                  <span class="content-date">{{ timestampToDateFormat(item.objBgngDt, 'hh:mm') }} - {{ timestampToDateFormat(item.objEndDt, 'hh:mm') }}</span>
                  <p class="content-title">{{ item.objNm }}</p>
                  <div class="badge" v-if="getClassNow(item.objBgngDt, item.objEndDt)">
                    <span class="badge-text">NOW</span>
                  </div>
                </div>
                <div class="course-type">
                  <span class="text">{{ item.lrnObjTyNm }}{{ item.lrnObjTyCd === lrnObjTyCds.Evaluation ? ` - ${item.evlTyNm}` : '' }}</span>
                </div>
              </div>
            </swiper-slide>
          </swiper>
      </div>
      <!-- today-course-nav -->
      <div class="today-learning-nav">
        <button class="kb-btn-nav kb-btn-kb-nav-prev kb-btn-kb-nav-schedule-prev"><i class="icon-arrow-prev"></i></button>
        <button class="kb-btn-nav kb-btn-kb-nav-next kb-btn-kb-nav-schedule-next"><i class="icon-arrow-next"></i></button>
      </div>
    </div>
  </section>
  <CloudNowPledgeModal v-if="applyModal"
                       v-model="applyModal"
                       :distCrseSn="pledge.distCrseSn"
                       :lrnerId="pledge.lrnerId"
                       :lrnerNm="pledge.lrnerNm"/>
</template>

<script>

import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {learnScheduledSetup} from '@/assets/js/modules/learn/learn-setup';
import CloudNowPledgeModal from "@/components/cloudNow/CloudNowPledgeModal";

export default {
  name: 'LearnScheduled',
  components: {Swiper, SwiperSlide, CloudNowPledgeModal},
  props: {
    items: Array,
    currentDate: Object
  },
  emits: ['openLearn'],
  setup: learnScheduledSetup
};
</script>
