<template>
  <div class="nav">
    <button v-for="(item, index) in items" class="kb-nav-link" :key="index" :class="{'is-active' : item.lrnObjDtlDistSn === currentLrnObjDtlDistSn}" @click="goBoardObject(item.lrnObjDtlDistSn)">
      <span class="title">{{ item.objNm }}</span>
    </button>
  </div>
</template>

<script>

import {learnBoardTapSetup} from '@/assets/js/modules/learn/learn-board-setup';

export default {
  name: 'LearnBoardTap',
  props: {
    items: Array
  },
  setup: learnBoardTapSetup
};
</script>
